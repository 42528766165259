




















import Navbar from "@/components/Core/Navbar.vue";
import Loading from "@/components/Web/Loading.vue";
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";

import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        Navbar,
        Loading

    },
    computed: {}
})

export default class Admin extends Vue {
    async created() {
        await Auth.checkToken();
        await User.loadUser();

        // if (User.routeUser == '/' || User.routeUser == '/admin/') {
        //     await this.$router.replace(User.routeUser)
        // }
    }

}
